
import React from 'react'
import { navigate } from 'gatsby'

import Meta from 'layout/Meta'
import SignInEmail from 'user/SignInEmail'
import SignInFacebook from 'user/SignInFacebook'


export default function SignInPage() {

	const done = () => navigate('/chat/')
	
	return <main>
		<Meta title="Sign in" />
		<SignInFacebook done={done} />
		<SignInEmail done={done} />
	</main>
}